import React from "react"
import { graphql, Link } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import SEO from "../components/seo"
import ActualiteGrid from "../components/ActualiteGrid"

import GridVoitures from "../components/GridVoitures"

const Marques = props => {
  const { data, pageContext } = props
  const { edges: models } = data.allTaxonomyTermModels
  const { edges: actualites } = data.allNodeActualites
  const marque = data.taxonomyTermMarques

  function createMarkup(field) {
    return { __html: field }
  }

  const imgStyles = {
    width: "100%",
    height: "350px",
    objectFit: "cover",
  }

  const style = {
    marginTop: "15px",
    marginBottom: "20px",
  }
  const settings = {
    lazyload: true,
    container: "#fixedWidth",
    mouseDrag: true,
    fixedWidth: 400,
    swipeAngle: false,
    speed: 400,
  }

  const imgs = []

  const loadingImage =
    "data:image/gif;base64,\
    R0lGODlhAQABAPAAAMzMzAAAACH5BAAAAAAALAAAAAABAAEAAAICRAEAOw=="

  return (
    <Layout>
      <SEO
        title={marque.field_title_seo}
        description={marque.field_description_seo}
        image={
          marque.relationships.field_image.localFile.childImageSharp.original
        }
      />

      <div className="section section-header pb-md-6 pb-lg-0 bg-soft">
        <div className="container z-2">
          <div className="row justify-content-end d-none d-lg-flex">
            <div className="col-auto">
              <div className="d-flex align-items-center flex-column flex-md-row z-2">
                <div className="nav-wrapper position-relative pb-4"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section pt-lg-0">
        <div id="spaces-container" className="container">
          <div className="row">
            <aside className="col-12 col-lg-4 d-block z-10">
              <div id="profile-sidebar" className="">
                <div className="sidebar-inner">
                  <div className="card mt-n7 d-lg-block border-light text-center p-2">
                    <div className="profile-cover rounded-top"></div>
                    <div className="card-body p-2">
                      <div className="profile-thumbnail small-thumbnail mt-n6 mx-auto">
                        {marque.relationships &&
                        marque.relationships.field_image ? (
                          <Img
                            className="card-img-top rounded-circle border-white"
                            style={{ width: "120px" }}
                            fluid={
                              marque.relationships.field_image.localFile
                                .childImageSharp.fluid
                            }
                            alt={pageContext.name}
                          />
                        ) : (
                          ""
                        )}
                      </div>
                      <h1 className="font-weight-normal mt-4 mb-0 h4 h4">
                        {pageContext.name} maroc
                      </h1>
                      <p>Prix voitures {pageContext.name} neuves au Maroc</p>
                      <span className="hp-hero__copy-description-marque">
                        {marque.description ? (
                          <div
                            dangerouslySetInnerHTML={createMarkup(
                              marque.description.processed
                            )}
                          />
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </aside>

            <div className="col-12 col-lg-8">
              <div className="tab-content mt-4">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                    <li className="breadcrumb-item">
                      <Link to={`/`}>Accueil</Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      {pageContext.name}
                    </li>
                  </ol>
                </nav>

                <h2 className="font-weight-normal mt-4 mb-0 h4">
                  Gamme des voitures {pageContext.name} neuves au Maroc (Prix et
                  fiche techniques)
                </h2>
                <div
                  className="tab-pane fade active show"
                  aria-labelledby="tab-grid-2-tab"
                >
                  <GridVoitures voitures={models} />
                </div>
              </div>
            </div>

            {actualites.length > 0 ? (
              <section className="section pb-0 mb-0 section-md overflow-hidden pt-2 bg-soft">
                <div className="container">
                  <h3 className="h2">Actualité</h3>

                  <ActualiteGrid
                    title="Actualité"
                    withHeader={false}
                    actualites={actualites}
                  />
                </div>
              </section>
            ) : (
              ""
            )}
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Marques

export const pageQuery = graphql`
  query MarquesPage($name: String!) {
    taxonomyTermMarques(name: { eq: $name }) {
      id
      name
      path {
        alias
      }
      description {
        value
        format
        processed
      }

      field_description_seo
      field_title_seo
      relationships {
        field_promo_images {
          uri {
            url
          }
          localFile {
            childImageSharp {
              resize {
                src
              }
              fluid {
                base64
                tracedSVG
                srcWebp
                srcSetWebp
                originalImg
                originalName
              }
            }
          }
        }
        field_image {
          localFile {
            childImageSharp {
              original {
                src
                width
                height
              }
              fluid {
                base64
                tracedSVG
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
                originalImg
                originalName
                presentationWidth
                presentationHeight
              }
            }
          }
        }
      }
    }
    allNodeActualites(
      limit: 3
      sort: { fields: id }
      filter: { relationships: { field_marques: { name: { eq: $name } } } }
    ) {
      edges {
        node {
          id
          title
          path {
            alias
          }
          field_description
          relationships {
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
        }
      }
    }
    allTaxonomyTermModels(
      filter: { relationships: { field_marques: { name: { eq: $name } } } }
    ) {
      edges {
        node {
          path {
            alias
          }
          name
          id
          field_isnew
          field_ispromo
          relationships {
            field_marques {
              path {
                alias
              }
            }
            field_image {
              localFile {
                childImageSharp {
                  fluid {
                    base64
                    tracedSVG
                    aspectRatio
                    src
                    srcSet
                    srcWebp
                    srcSetWebp
                    sizes
                    originalImg
                    originalName
                    presentationWidth
                    presentationHeight
                  }
                }
              }
            }
          }
          field_prix_min
          field_prix_max
          field_chevaux_fiscale
          field_carburant
          field_chevaux
          field_isnew
          field_ispromo
        }
      }
    }
  }
`
