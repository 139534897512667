import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Img from "gatsby-image"

export default class GridVoitures extends React.Component {
  render() {
    const { voitures, colmd } = this.props
    let classcol = 6
    if (typeof colmd !== "undefined") {
      classcol = colmd
    }
    return (
      <div className="row">
        {voitures.map(({ node: model }) => (
          <div key={model.id} className={`col-12 col-md-${classcol}`}>
            <div className="card border-light mb-4 animate-up-5">
              {model.relationships.field_image &&
              model.relationships.field_image !== null &&
              model.relationships.field_image.localFile.childImageSharp !==
                null ? (
                <Link
                  to={`${model.relationships.field_marques.path.alias}${model.path.alias}/`}
                  className="position-relative"
                >
                  <Img
                    className="card-img p-0 rounded-xl"
                    fluid={
                      model.relationships.field_image.localFile.childImageSharp
                        .fluid
                    }
                    alt={`${model.name}`}
                  />
                </Link>
              ) : (
                ""
              )}

              <div className="card-body">
                <Link
                  to={`${model.relationships.field_marques.path.alias}${model.path.alias}/`}
                >
                  <h3 className="h4">{model.name}</h3>
                </Link>
                <div className="d-flex my-1">
                  {" "}
                  {model.field_ispromo ? (
                    <span className="badge badge-pill badge-danger ml-2">
                      PROMO
                    </span>
                  ) : (
                    ""
                  )}
                  {model.field_isnew ? (
                    <span className="badge badge-pill badge-danger ml-2">
                      NEW
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="card-footer bg-soft border-top p-0">
                <ul className="list-inline row mx-auto my-0">
                  <li className="list-inline-item col-sm-6 col-auto px-2 px-xl-3 my-2 mx-0 list-group-item small p-0">
                    Prix :
                    <br />
                    {model.field_prix_min} - {model.field_prix_max} DH
                  </li>
                  <li className="list-inline-item col-sm-6 col-auto px-2 px-xl-3 my-2 mx-0 list-group-item small p-0">
                    Motorisation :
                    <br />
                    {model.field_carburant} - {model.field_chevaux_fiscale} -{" "}
                    <b>{model.field_chevaux}</b>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        ))}
      </div>
    )
  }
}
